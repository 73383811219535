.application {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1 1;
}

.app-root {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1 1;
  min-width: 0;
}
