[slot="header"] .env-switcher {
  margin: 0;
}

lume-side-panel {
  --lume-c-side-panel-sizing-min-width: 270px;
  --lume-c-side-panel-sizing-width: 270px;
  --lume-c-side-panel-spacing-block-start: 16px;
  --lume-c-side-panel-spacing-block-end: 16px;
  --lume-c-side-panel-spacing-inline-start: 16px;
  --lume-c-side-panel-spacing-inline-end: 16px;
  --lume-c-side-panel-header-spacing-block-end: 16px;
  --lume-c-side-panel-product-icon-spacing-inline-end: 4px;
  --lume-c-side-panel-product-name-spacing-block: 6px;
  --lume-c-side-panel-product-name-radius-border: 8px;
  --lume-c-side-panel-product-name-font-size: 16px;
  --lume-c-side-panel-product-icon-sizing: 24px;
}

.menu-back-icon {
  margin-right: 7px;
}

.menu-back {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--lume-g-color-text-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 20px;
  position: relative;
  text-decoration: none
}

.menu-back:hover {
  color: var(--lume-g-color-text-primary);
}

.menu-back:active,
  .menu-back:focus {
  color: var(--primary);
}

.menu-back:focus {
  outline: none;
}

.env-switcher + .menu-back {
  padding-top: var(--lume-c-side-panel-header-spacing-block-end);
}
